<template>
  <div>
    <p class="empty-ph">
      <b>{{ this.summaryTitle }}</b>
      <br/>{{ this.summaryStart }} <dropdown-ui
      id="dailyPayoutSelector2"
      :currency="currency"
      :possible-values="possibleDailyPayoutValues"
      :default-value="dailyPayout"
      selection-color="black"
      @selected="updateDailyPayout"
    /> {{ this.summaryEndFormatted }}
    </p>
    <p class="empty-ph" v-if="showPriceTitle">
      <b>{{ this.priceTitleFormatted }}</b>
    </p>
  </div>
</template>

<script>
import FormatService from '@/services/FormatService';
import LogService from '@/services/LogService';
import dropdownUi from '@/components/ui/dropdownUi.vue';

export default {
  name: 'vacationSummary',
  components: { dropdownUi },
  props: {
    summaryTitle: {
      type: String,
      required: true,
    },
    summaryStart: {
      type: String,
      required: true,
    },
    summaryEnd: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    possibleDailyPayoutValues: {
      type: Array,
      required: true,
    },
    initialDailyPayout: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    priceTitle: {
      type: String,
      require: true,
    },
    maxPayout: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    showPriceTitle: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    summaryEndFormatted() {
      return FormatService.format(
        this.summaryEnd,
        this.maxPayout,
        this.currency,
      );
    },
    priceFormatted() {
      LogService.log(
        'priceFormatted',
        this.lang,
        new Intl.NumberFormat(this.lang, { minimumFractionDigits: 2 }).format(this.price),
      );
      return `${new Intl.NumberFormat(this.lang, { minimumFractionDigits: 2 })
        .format(this.price)} ${this.currency}`;
    },
    priceTitleFormatted() {
      return `${this.priceTitle} ${this.priceFormatted}`;
    },
  },
  data() {
    return {
      dailyPayout: this.initialDailyPayout,
    };
  },
  methods: {
    updateDailyPayout(newValue) {
      LogService.log('mainSection daily payout updated', newValue);
      this.dailyPayout = newValue;
      this.$emit('update-daily-payout', newValue);
    },
  },
  watch: {
    initialDailyPayout(newValue) {
      this.dailyPayout = newValue;
    },
  },
};
</script>
