<template>
  <span class="dd-main" :class="{
        open: isOpen,
      }" :id="id">
    <span @click="isOpen = !isOpen">
      <a class="dd-selection" :style="cssSelectionColor">
        {{value}} {{currency}}
      </a>
    <openArrow
      :is-open="isOpen"
      :color="selectionColor"
      :width="arrowSeize"
    ></openArrow>
    </span>
    <div v-if="isOpen" class="dd-list">
      <div v-for="(item, i) in possibleValues" :key="i" @click="select(item, i)" class="dd-entries"
           :class="{
        selected: i === selectedIndex,
      }">
        <a :style="cssSelectionColor">
          {{item}} {{currency}}
        </a>
      </div>
    </div>
  </span>
</template>

<script>
import LogService from '@/services/LogService';
import openArrow from './openArrow.vue';

export default {
  props: {
    id: {
      type: String,
      default: 'dropdown',
    },
    selectionColor: {
      type: String,
      required: true,
    },
    arrowSeize: {
      type: Number,
      default: 10,
    },
    defaultValue: {
      default: 100,
    },
    currency: {
      type: String,
      required: true,
    },
    possibleValues: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: this.defaultValue,
      isOpen: false,
      selectedIndex: undefined,
    };
  },
  watch: {
    defaultValue(newValue) {
      this.value = newValue;
      this.updateSelectedIndexByValue(newValue);
    },
  },
  created() {
    this.updateSelectedIndexByValue(this.defaultValue);
  },
  components: {
    openArrow,
  },
  methods: {
    updateSelectedIndexByValue(selectedValue) {
      this.selectedIndex = this.possibleValues.indexOf(selectedValue);
      LogService.log('dropdown defaultValue changed, change selected index', this.selectedIndex);
    },
    select(selected, index) {
      this.value = selected;
      this.selectedIndex = index;
      this.isOpen = false;
      this.$emit('selected', selected);
      LogService.log('dropdown, selected', selected);
    },
  },
  computed: {
    cssSelectionColor() {
      return {
        color: this.selectionColor,
      };
    },
  },
};
</script>

<style scoped>
.dd-main {
  position: relative;
  padding: 2px 2px 2px 4px;
  border: 1px solid var(--border-input-color);
  border-radius: calc(2rem - 2px)
}
.dd-main:hover, .dd-main.open {
  box-shadow: 0 5px .5rem rgba(0, 0, 0, 0.2);
}
.dd-selection {
  cursor: pointer;
  font-weight: bold;
}
.dd-list {
  position: absolute;
  background-color: white;
  z-index: 9;
  left: 0;
}
.dd-entries {
  text-align: right;
  cursor: pointer;
  padding-left: 6px;
}
.dd-entries.selected, .dd-entries:hover {
  background-color: #f6f6f6;
}

.dd-entries:hover {
  box-shadow: 0 5px .5rem rgba(0, 0, 0, 0.2);
}
</style>
