class MergeHelper {
  static mergeObjects(obj1, obj2, doNotOverwrite = false) {
    Object.keys(obj2).forEach((element) => {
      if (obj2?.[element] !== null && typeof obj1?.[element] === 'object' && typeof obj2?.[element] === 'object') {
        this.mergeObjects(obj1[element], obj2[element]);
        // eslint-disable-next-line no-prototype-builtins
      } else if ((!doNotOverwrite && obj1.hasOwnProperty(element) && !(obj2[element] == null))
        // eslint-disable-next-line no-prototype-builtins
        || !obj1.hasOwnProperty(element)) {
        // eslint-disable-next-line no-param-reassign
        obj1[element] = obj2[element];
      }
    });
    return obj1;
  }
}

export default MergeHelper;
