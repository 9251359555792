class FormatService {
  static format(template, ...args) {
    // formatting string such as 'lorem %s ispum' and replace %s to param from args
    let n = -1;
    return template && template.replace(/(%s)/g, () => {
      n += 1;
      return args[n];
    });
  }
}

export default FormatService;
