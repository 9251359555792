<template>
  <div>
    <!-- FinalizeVacation -->
  </div>
</template>
<script>
import LogService from '@/services/LogService';
import common from '@/mixins/common';
import { nanoid } from 'nanoid';
import { errorStrings } from '@/constants';
import wpSection from '@/enums/wpSection';
import { productNames, eventNames, currencyAbbreviation } from '@/enums/googleTagManager';
import setLayout from '@/mixins/setLayout';

export default {
  mixins: [common, setLayout],
  data() {
    return {
      str: {
        finalizeVacation: {
          title: '',
          error_payment_failed: '',
          error_finalize_failed: '',
        },
        commonApp: {
          ok: '',
        },
      },
      progress: [3, 3],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const loadingId = nanoid();
      LogService.log('creating screen, loadingId', this.loadingId);
      this.$store.commit('SET_LOADING', loadingId);
      this.$store.commit('SET_APP_LAYOUT', { progress: this.progress });
      if (this.$route.query.token) {
        LogService.log('set token', this.$route.query.token);
        this.$store.commit('SET_TOKEN', this.$route.query.token, { root: true });
      }
      await this.getWordPressStrings(
        [
          wpSection.FINALIZE_VACATION,
          wpSection.COMMON_APP,
        ],
        wpSection.FINALIZE_VACATION,
      );
      const orderId = this.$route.query.order_id ?? undefined;
      const checkRes = await this.checkPayoutStatus(orderId);
      LogService.log('paymentSuccessful', checkRes.paymentSuccess);
      if (checkRes.paymentSuccess) {
        if (this.$route.query.from_iframe && checkRes.data.iframe_vacation_success_url) {
          LogService.log('set iFrameVacationSuccessUrl', checkRes.data.iframe_vacation_success_url);
          sessionStorage.setItem('iFrameVacationSuccessUrl', checkRes.data.iframe_vacation_success_url);
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: eventNames.purchase,
          ecommerce: {
            transaction_id: checkRes.data.order_id,
            value: checkRes.data.price,
            currency: currencyAbbreviation.euro,
            tax: checkRes.data.tax,
            items: [
              {
                item_name: productNames.vacation,
                affiliation: checkRes.data.partner,
                price: checkRes.data.price,
                location_id: checkRes.data.place_id,
                item_category: checkRes.data.dates.at(0),
                item_category2: checkRes.data.dates.at(-1),
                item_category3: checkRes.data.dates.length,
                item_category4: checkRes.data.risk_days,
                item_category5: checkRes.data.daily_payout,
                item_variant: checkRes.data.location,
                promotion_id: checkRes.data.promotion_id,
                quantity: 1,
              },
            ],
          },
        });
        this.$store.commit('DEL_LOADING', loadingId);
        this.$router.push({ name: 'success', params: { ...this.$route.params } });
      }
    },
    async checkPayoutStatus(orderId) {
      let paymentSuccess = true;
      const data = {};
      if (orderId) {
        data.order_id = orderId;
      }
      const res = await this.$webAppAPI.checkPayoutStatus(data).catch((e) => {
        LogService.log('checkPayoutStatus error', e, e.response?.data?.message, e.response?.data?.message === errorStrings.invalidUser);
        this.$store.commit('RESET_LOADING');
        if (e.response?.data?.message === errorStrings.invalidUser) {
          this.$router.push({ name: 'vacation' });
        } else if (e.response?.data?.message === errorStrings.orderNotPaidYet) {
          this.showModal(this.str.finalizeVacation.error_payment_failed);
          this.$router.push({ name: 'buy-vacation' });
        } else {
          this.showModal(this.str.finalizeVacation.error_finalize_failed);
        }
        LogService.log('checkPayoutStatus, setting paymentSuccessful false');
        paymentSuccess = false;
      });
      return { paymentSuccess, data: paymentSuccess ? res.data : undefined };
    },
    async callOrderBuyApi() {
      await this.$webAppAPI.postBuy();
    },
    showModal(errorText) {
      // show the popup error
      const modal = {
        id: 'wet-finalize-vacation-payment-failed-modal',
        content: `<div class="text-center">${errorText}</div>`,
        actions: [
          {
            name: 'wet-finalize-vacation-payment-failed-modal-ok',
            title: this.str.commonApp.ok,
            event: ['close', this.back],
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$emit('update:nextTitle', '');
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
};
</script>

<style>

</style>
