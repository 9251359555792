<template>
  <svg class="dd-arrow" :class="{open: isOpen}"
       :style="{'--color_fill': color,'margin-left': `${marginLeft}px`,}" :width="`${width}px`"
       :height="`${width}px`">
    <use href="@/assets/images/svg/arrow-down.svg#img"></use>
  </svg>
</template>
<script>
import Integer from 'vuelidate/lib/validators/integer';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Integer,
      default: 22,
    },
    color: {
      type: String,
      default: 'black',
    },
    marginLeft: {
      type: Integer,
      default: 3,
    },
  },
};
</script>

<style lang="scss" scoped>
  .dd-arrow {
    transition: all ease-out 0.2s;
  }
  .dd-arrow.open {
    transition: all ease-out 0.2s;
    transform: rotate(-180deg);
  }
</style>
